
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { casingFlex } from "@/util/print";
import Printd from "printd";
import { DateTime } from "luxon";
import VueBarcode from "vue-barcode";
import { formatTimestamp, padLotNumber } from "@/util/helpers";
import QrcodeVue from "qrcode.vue";
import Lot from "@eaua/model";
import { get } from "lodash";

const cdigit = require("cdigit");

@Component({
  components: {
    barcode: VueBarcode,
    QrcodeVue,
  },
})
export default class CaseForm extends Vue {
  // VARIABLE DEFINITIONS -----------------------------------------
  // Define Props

  @Prop({ default: () => {} })
  packoutGood!: any;

  @Prop({ default: () => [] })
  consolidatedGoods!: any;

  @Ref("casingLabel")
  readonly casingLabel!: HTMLElement;

  // ---------------------------------------------------------------------------
  // COMPUTED
  // ---------------------------------------------------------------------------
  /**
   * Returns currentLot uuid from store
   * @returns {string}
   */
  get currentLotUuid() {
    let currentLot = new Lot(this.$store.state.lots.currentLot, this.$store);
    return currentLot.uuid;
  }

  get currentSublotNumber() {
    let currentLot = new Lot(this.$store.state.lots.currentLot, this.$store);
    return currentLot.name;
  }

  get refrigerate() {
    if (get(this.packoutGood, "inventory_item.refrigerate", false))
      return "KEEP REFRIGERATED";

    return "DO NOT REFRIGERATE";
  }

  /**
   * Returns packoutGood inventory_item gtin number for barcode value and display
   * @returns {string}
   */
  get barcodeValue() {
    return this.packoutGood.inventory_item.gtin;
  }

  convertToQrCode(item: any = {}) {
    return JSON.stringify({
      type: "navigate-lot",
      payload: item.uuid,
    });
  }

  /**
   * Returns UCC-128/GS1 barcode value
   * comprised of Application Identifiers, padded barcode value,
   * manual check digit number, useby 6 digit date, padded lot number
   * @returns {string}
   */
  get topBarcodeValue() {
    return `0110${this.barcodeValue}${this.checkDigit}15${this.bestUseByDate(
      "useBy"
    )}10${this.lotNumber}`;
  }

  /**
   * Returns UCC-128/GS1 barcode value
   * comprised of Application Identifiers(in parenthesis), padded barcode value,
   * manual check digit number, useby 6 digit date, padded lot number
   * @returns {string}
   */
  get topBarcodeText() {
    return `(01)10${this.barcodeValue}${
      this.checkDigit
    }(15)${this.bestUseByDate("useBy")}(10)${this.lotNumber}`;
  }

  /**
   * Returns check digit algorithm for GTIN-14 barcode
   * @returns {number}
   */
  get checkDigit() {
    return cdigit.gtin.compute(`10${this.barcodeValue}`);
  }

  /**
   * Returns true boolean here
   * attribute in barcode component was rendering string
   * @returns {boolean}
   */
  get returnTrue() {
    return true;
  }

  /**
   * Returns date of when materials were scanned
   * @returns {string}
   */
  get packoutMaterialCreatedDate() {
    return this.packoutGood.materials[0].created;
  }

  /**
   * Returns formatted date of when materials were scanned
   * @returns {string}
   */
  get packoutMaterialFormattedCreatedDate() {
    return formatTimestamp(this.packoutMaterialCreatedDate, "simple-condensed");
  }
  /**
   * Returns formatted use by date for GS1
   * barcode value based on "useBy" type
   * Returns formatted item's best by offset
   * date based on "bestBy" type
   * @returns {boolean}
   */
  bestUseByDate(type: string) {
    switch (type) {
      case "bestBy":
        return DateTime.fromISO(this.packoutMaterialCreatedDate)
          .plus({ days: this.packoutGood.inventory_item.best_by_offset })
          .toUTC()
          .toFormat("MM/dd");
      case "useBy":
        return DateTime.fromISO(this.packoutMaterialCreatedDate)
          .plus({ days: this.packoutGood.inventory_item.best_by_offset })
          .toUTC()
          .toFormat("YYMMDD");
    }
  }

  /**
   * Returns packoutGood padded rel_lot_header number for label display
   * @returns {number}
   */
  get lotNumber() {
    return padLotNumber(this.packoutGood.rel_lot_header.number);
  }

  /**
   * Returns packoutGood inventory item number
   * @returns {number}
   */
  get itemNumber() {
    return this.packoutGood.inventory_item.number;
  }

  /**
   * Returns label package description
   * @returns {string}
   */
  get casePackLine() {
    const unitWeight = this.packoutGood.inventory_item.internal_unit_weight;
    const unitUom = this.packoutGood.inventory_item.internal_unit_weight_uom;
    const unitPack = this.packoutGood.inventory_item.internal_unit_packaging;
    const unitCount = this.packoutGood.inventory_item.internal_unit_count;
    return `${unitWeight} ${unitUom} ${unitPack} / ${unitCount} Count Case`;
  }

  /**
   * Returns label item name
   * @returns {string}
   */
  get catalogProductName() {
    return this.packoutGood.inventory_item.item_label_name;
  }

  /**
   * Returns boolean if item is a food service item.  If true, hide best by info on label.
   */
  get isNotFoodService() {
    const customerTarget = get(
      this.packoutGood.inventory_item,
      "customer_target",
      ""
    );
    if (customerTarget != "Foodservice") {
      return true;
    } else {
      return false;
    }
  }

  get brandLabel() {
    if (
      get(this.packoutGood.inventory_item, "brand_id", "") ==
      "419c1d54-ae55-4a0a-92d3-3c3b685a3c9e"
    ) {
      return require(`@/modules/core/assets/HaloHarvest_Logo.svg`);
    } else if (
      get(this.packoutGood.inventory_item, "brand_id", "") ==
      "7e2f769f-ae6c-4ea5-8034-3b5b8c355666"
    ) {
      return require(`@/modules/core/assets/BeleavesGardensLogo.svg`);
    }
    return require(`@/modules/core/assets/80AcresFarms_Logo.svg`);
  }

  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------
  /**
   * Triggers the browser's print dialog
   */
  print() {
    let printd: Printd = new Printd();
    printd.print(this.casingLabel as HTMLElement, [casingFlex]);
  }
}
